'use client';

import { Copyright, Facebook, GitHub, LinkedIn } from '@mui/icons-material';
import { motion } from 'framer-motion';

import Logo from '../logos/Logo';

type FooterProps = {};
export default function Footer(props: FooterProps) {
  const socialItemClassName =
    'hover:cursor-pointer will-change-transform text-lg flex' +
    ' items-center' +
    ' justify-center' +
    ' w-[32px] aspect-square rounded text-neutral-content';
  const itemAnimationProps = {
    whileHover: {
      scale: 1.1,
    },
  };
  return (
    <footer className="footer flex flex-col items-start justify-between gap-2 bg-secondary px-8 py-4 text-neutral-content sm:flex-row">
      <aside className="flex flex-row items-center gap-4">
        <Logo className="size-8 [&_g]:fill-neutral-content" />
        <p>
          All right reserved <Copyright /> {new Date().getFullYear()}
        </p>
      </aside>
      <nav>
        <ul className="flex flex-row gap-4">
          <motion.li className={socialItemClassName} {...itemAnimationProps}>
            <a target="_blank" href="https://github.com/huubinh49" aria-label="See github profile">
              <GitHub />
            </a>
          </motion.li>
          <motion.li className={socialItemClassName} {...itemAnimationProps}>
            <a
              target="_blank"
              href="https://www.facebook.com/huubinh49/"
              aria-label="See facebook profile"
            >
              <Facebook />
            </a>
          </motion.li>
          <motion.li className={socialItemClassName} {...itemAnimationProps}>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/nguyen-huu-binh-81b4611a2/"
              aria-label="See linkedIn profile"
            >
              <LinkedIn />
            </a>
          </motion.li>
        </ul>
      </nav>
    </footer>
  );
}
