'use client';

import { useEffect } from 'react';
import { useAnimate } from 'framer-motion';

const Separator = ({ index }: { index: number }) => {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const animation = async () => {
      animate([
        ['#vertical', { scaleY: 0 }, { type: 'tween', duration: 0.16 }],
        [
          '#horizontal',
          {
            scaleX: 0,
            scaleY: 1,
            x: '-50%',
          },
          { type: 'tween', duration: 0.16 },
        ],
        [
          '#horizontal',
          {
            scaleX: 1,
            scaleY: 1,
            x: '-50%',
          },
          { type: 'tween', duration: 0.16 },
        ],
        ['#vertical', { scaleY: 1 }, { type: 'tween', duration: 0.16 }],
      ]);
    };
    animation();
  }, [index, animate]);

  return (
    <div ref={scope} className="relative">
      <div
        id="vertical"
        className={
          'h-full w-1 shrink-0 grow-0 basis-1 origin-top bg-primary-content will-change-transform'
        }
      ></div>
      <div
        id="horizontal"
        className="absolute left-0 top-0 h-1 w-[200px] origin-top-left -translate-x-1/2 bg-primary-content will-change-transform"
      />
    </div>
  );
};

export default Separator;
