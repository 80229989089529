import PinnedProject from './PinnedProject';
import ProjectLineItem from './ProjectLineItem';
import Separator from './Separator';

type ICategorySection = {
  name: string;
  description: string;
  index: number;
  projects: Array<any>;
  currentProject: number;
  setCurrentProject(project: number): void;
};

export default function CategorySection({
  name,
  description,
  index,
  projects,
  setCurrentProject,
  currentProject,
}: ICategorySection) {
  return (
    <div className="my-auto flex h-full flex-row px-8">
      {/* // Heading */}
      <div className="w-full md:basis-2/5">
        <div className="relative w-fit min-w-[160px]">
          <h2 className="absolute bottom-0 left-0 inline-block text-center text-4xl font-bold text-primary-content">
            {name}
          </h2>
          {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
          <span className="text-primary-content-100 text-8xl opacity-30 md:text-9xl">
            0{index + 1}
          </span>
        </div>
        <div className="flex h-full flex-row">
          <Separator index={index} />
          <div className="flex flex-col gap-2 p-8 md:flex-row">
            <div className="flex flex-col gap-6">
              <p className="text-lg font-thin text-secondary-content">{description}</p>
              <h3 className="text-3xl uppercase text-primary-content">
                {projects.length} projects
              </h3>

              <ul className="">
                {projects.map(
                  (
                    {
                      name,
                      url,
                    }: {
                      name: string;
                      url: string;
                    },
                    index: number
                  ) => (
                    <ProjectLineItem
                      onMouseEnter={() => setCurrentProject(index)}
                      key={name}
                      index={index}
                      name={name}
                      url={url}
                    />
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <PinnedProject
        {...(projects[currentProject] || projects[0])}
        className="hidden md:flex md:basis-3/5"
      />
    </div>
  );
}
