'use client';

import { useEffect, useRef, useState } from 'react';
import { useScroll } from 'framer-motion';

import CategorySection from './CategorySection';

type IProjectSection = {
  categories: Array<any>;
};

export default function ProjectSection({ categories }: IProjectSection) {
  const [currentCategory, setCurrentCategory] = useState(0);
  const [currentProject, setCurrentProject] = useState(0);
  const projectSectionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: projectSectionRef,
  });
  const { projects, name, description } = categories[currentCategory];

  scrollYProgress.on('change', (value) => {
    if (value < 1 / 3) {
      setCurrentCategory(0);
    } else if (value >= 1 / 3 && value < 2 / 3) {
      setCurrentCategory(1);
    } else {
      setCurrentCategory(2);
    }
  });

  useEffect(() => {
    setCurrentProject(0);
  }, [currentCategory]);

  return (
    <section className="h-[300vh]" ref={projectSectionRef}>
      <div className="sticky left-0 top-0 z-10 h-screen w-full py-16">
        <CategorySection
          name={name}
          currentProject={currentProject}
          setCurrentProject={setCurrentProject}
          index={currentCategory}
          description={description}
          projects={projects}
        />
      </div>
    </section>
  );
}
