'use client';

import { cubicBezier, motion, useScroll, useTransform } from 'framer-motion';

import Logo from '../logos/Logo';
import { ThemeToggle } from '../Theme/theme-toggle';

const Header = () => {
  const glassMorphismClass =
    'bg-primary-content-100 px-4 py-2 rounded-md bg-clip-padding' +
    ' backdrop-filter backdrop-blur-md bg-opacity-10 flex items-center justify-center';
  const headerClassName = `will-change-[transform, opacity] sticky top-0 left-0 z-20 flex-nowrap flex-col items-center justify-center h-fit w-full`;

  const { scrollYProgress } = useScroll();
  const width = useTransform(scrollYProgress, [0, 1], ['100%', '0%'], {
    ease: cubicBezier(0.17, 0.67, 0.83, 0.67),
  });

  return (
    <motion.header
      animate={{
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          delay: 2,
        },
      }}
      initial={{
        y: -100,
        opacity: 0,
      }}
      transition={{
        duration: '1s',
      }}
      className={headerClassName}
    >
      <div className="h-1 w-full bg-[linear-gradient(to_right,theme(colors.indigo.400),theme(colors.indigo.100),theme(colors.sky.400),theme(colors.fuchsia.400),theme(colors.sky.400),theme(colors.indigo.100),theme(colors.indigo.400))]">
        <motion.div
          initial={{
            width: '100%',
          }}
          style={{
            width,
          }}
          className="float-right h-full bg-primary"
        />
      </div>
      <nav className={'flex h-fit w-full justify-center' + ' items-center' + ' bg-transparent'}>
        <ul
          className={
            'flex h-full flex-nowrap items-center justify-between' + ' w-full list-none px-8 py-4'
          }
        >
          <li className={'cursor-pointer'}>
            <a
              aria-label="Scroll to the introduction section"
              href="#intro"
              className={
                'decoration-none h-max w-max text-lg font-bold hover:cursor-pointer' +
                ' hover:text-primary-color align-baseline'
              }
            >
              <Logo
                className={
                  'h-[32px] w-[32px] cursor-pointer md:h-[48px] md:w-[48px]' +
                  ' [&>g]:fill-primary-content'
                }
              />
            </a>
          </li>
          <li className={'flex cursor-pointer gap-1 md:gap-4'}>
            <motion.a
              className={'text-xs text-primary-content md:text-lg ' + glassMorphismClass}
              href={
                'https://binh-portfolio-website.s3.ap-southeast-1.amazonaws.com/Binh_Nguyen_SE.pdf'
              }
              rel="noreferrer"
              target={'_blank'}
              whileHover={{
                scale: 1.05,
              }}
            >
              DOWNLOAD CV
            </motion.a>
            <ThemeToggle />
          </li>
        </ul>
      </nav>
    </motion.header>
  );
};
export default Header;
