'use client';

import React, { useEffect, useState } from 'react';

import Logo from '../logos/Logo';
import css from './PreLoader.module.css';

export default function PreLoader() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <section className={`${css.overlay} ${loading ? '' : '!hidden'}`}>
      <Logo className={css.logo} />
    </section>
  );
}
