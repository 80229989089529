'use client';

import { ChangeEvent, FormEvent, useState } from 'react';
import Link from 'next/link';
import { paddingXClassName } from '@/config/constants';

import Checkmark from '../Checkmark/Checkmark';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Logo from '../logos/Logo';

export default function Contact() {
  const [values, setValues] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const inputFieldClass =
    'w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-sm focus:outline-none focus:shadow-outline';
  const labelFieldClass = 'uppercase text-sm font-bold';
  const [sendEmailState, setSendEmailState] = useState<{
    sending: boolean;
    error: Error | null;
    succeed: boolean;
  }>({
    sending: false,
    error: null,
    succeed: false,
  });

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      setSendEmailState({
        sending: true,
        error: null,
        succeed: false,
      });
      await fetch('/api/contact', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSendEmailState({
        sending: false,
        error: null,
        succeed: true,
      });
    } catch (err: unknown) {
      setSendEmailState({
        sending: false,
        error: new Error((err as Error).message),
        succeed: false,
      });
    }
  }
  const handleFieldChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = event.target as HTMLInputElement | HTMLTextAreaElement;
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };
  const emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g;
  const isFormValid =
    values.fullName && emailRegex.test(values.email) && values.message && !sendEmailState.sending;

  const title = (
    <>
      <h2 className="text-4xl font-bold leading-tight lg:text-5xl">Lets talk about everything!</h2>
      <div className="mt-8 text-gray-700">
        Hate forms? Send us an{' '}
        <Link
          className="font-bold underline"
          href={'mailto:huubinh0409@gmail.com'}
          aria-label="The author\'s email"
        >
          email
        </Link>{' '}
        instead.
      </div>
    </>
  );
  return (
    <section
      className={'mb-16 h-max w-full overflow-hidden bg-gray-200 dark:bg-gray-200'}
      id="contact"
    >
      <div
        className={`mx-auto flex flex-col-reverse gap-8 rounded-sm py-16 text-gray-900 shadow-lg md:flex-row ${paddingXClassName}`}
      >
        <div className="hidden basis-1/2 flex-col justify-between gap-8 md:flex">
          {title}
          <Logo className={'w-[280px]'} />
        </div>
        <form className="flex basis-1/2 flex-col gap-8" onSubmit={handleSubmit}>
          <div className="block md:hidden">{title}</div>
          <div>
            <label className={labelFieldClass}>Full Name</label>
            <input
              className={inputFieldClass}
              type="text"
              placeholder=""
              name="fullName"
              onChange={handleFieldChange}
              autoCapitalize="on"
              value={values.fullName}
              required
            />
          </div>
          <div>
            <label className={labelFieldClass}>Email</label>
            <input
              className={inputFieldClass}
              type="email"
              name="email"
              onChange={handleFieldChange}
              required
              autoComplete="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            />
          </div>
          <div>
            <label className={labelFieldClass}>Message</label>
            <textarea
              className={inputFieldClass}
              name="message"
              onChange={handleFieldChange}
              autoCapitalize="on"
              spellCheck
              autoCorrect="on"
              required
            />
          </div>
          {sendEmailState.error && (
            <p className={'text-error'}>{'Something went wrong, please try again!'}</p>
          )}
          <button
            className={`mt-8 flex h-[50px] w-full items-center justify-center rounded-sm bg-secondary p-3 text-sm font-bold uppercase tracking-wide text-gray-100 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 ${sendEmailState.succeed ? 'bg-green-500' : 'bg-secondary'} `}
            type="submit"
            disabled={!isFormValid}
          >
            {sendEmailState.sending ? (
              <LoadingSpinner />
            ) : sendEmailState.succeed ? (
              <Checkmark />
            ) : (
              'Send Message'
            )}
          </button>
        </form>
      </div>
    </section>
  );
}
